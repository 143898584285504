<template>
	<div class="campaign-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Campaign" filter="name" sort="beginAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="campaigns"
		               v-on:add-new="addCampaign"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import {format} from "date-fns";

export default {
	name: "CampaignTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Name", key: "name", classBinding: ["f-5"] },
				{ display: "Client", key: "client", classBinding: ["f-5"] },
				{ display: "Begin", key: "beginAt", classBinding: ["f-2"] },
				{ display: "End", key: "endAt", classBinding: ["f-2"] },
			],
		}
	},
	
	computed: {
		campaigns() {
			return this.$store.state.contents.campaigns.map(campaign => {
				const obj = {...campaign};
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === campaign.client);
				obj.client = client ? `${client.name} (${client.clientNo})` : "N/A";
				obj.beginAt = campaign.beginAt ? format(new Date(campaign.beginAt), "yyyy-MM-dd") : "N/A";
				obj.endAt = campaign.endAt ? format(new Date(campaign.endAt), "yyyy-MM-dd") : "N/A";
				return obj;
			});
		}
	},
	
	methods: {
		async addCampaign() {
			await this.$router.push("/project/campaign/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/project/campaign/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>